html {
  font-size: 100%;
}

.inner {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 980px) {
  .inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 980px) {
  .navhamberg {
    display: none;
  }
}
.navhamberg {
  width: 30px;
  height: 25px;
  position: absolute;
  right: 15px;
}
.navhamberg .bar {
  transition: all .2s linear 0s;
  height: 1px;
  width: 100%;
  display: block;
  position: absolute;
  background: #000;
}
.navhamberg .bar:nth-child(1) {
  top: 0;
  left: 0;
}
.navhamberg .bar:nth-child(2) {
  top: 50%;
  left: 0;
}
.navhamberg .bar:nth-child(3) {
  bottom: 0;
  left: 0;
}

.spmenu_wrap {
  width: 100%;
  height: 100vh;
  background: #ddd;
  position: fixed;
  z-index: 2;
  top: 990;
  top: 0;
  transition: all .2s linear 0s;
  transform: translateY(-100vh);
}

body.active .spmenu_wrap {
  transform: translateY(0);
}
body.active .navhamberg .bar:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: left top;
  top: 3px;
  left: 5px;
}
body.active .navhamberg .bar:nth-child(2) {
  width: 0;
}
body.active .navhamberg .bar:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: left bottom;
  left: 5px;
}

.carousel-control-prev, .carousel-control-next {
  width: 5%;
}
